.user-name {
    font-size: 1.2em;
    font-weight: bold;
    color: #4fd1c5;
    text-transform: uppercase;
}

.logoutbtn {
    background: #15d7c5;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 2;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.shadow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.search-bar{
    visibility: hidden;
}

.search-box{
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.search-icon{
    position: absolute;
    right: 10;
    margin-left: 200px;
    margin-top: 8px;
    color: grey;
}

.search-icon-2{
    position: absolute;
    right: 10;
    margin-left: -54px;
    margin-top: 10px;
    color: grey;
}

.mt-2
{
    margin-top: 1rem;
}

.items-container{
    margin-top: 1rem;
    background-color: rgb(255, 255, 255);
    padding: 0.3rem;
    box-shadow: 0px 1px 10px 9px rgb(104 96 96 / 7%);
    border-radius: 5px;
}

.items-container h3{
    color: rgb(59 57 57 / 40%);
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
.items-container .items{
    background-color: #ffffff;
    border: 1px solid rgba(254, 254, 254, 0.889);
    box-shadow: 0px 1px 10px 9px rgb(104 96 96 / 7%);
    border-radius: 5px;
    padding:1rem;
    margin-top: 1rem;
}

.add-item-btn{
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 2;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 0.6rem;
}

.orange-btn:disabled{
    background: hsla(35, 100%, 55%, 0.336);
    cursor: not-allowed;
}

.green-btn{
    background-color: #15d7c5;
}

.orange-btn{
    background-color: hsl(35, 100%, 55%);
}

.item-close{
    display: flex;
    justify-content: flex-end;
    padding: 3px;
}

.item-close-icon{
    color: #15d7c5;
    font-size: 1.2rem;
    cursor: pointer;
}

.search-ul-box{
    /* background: #0000000d; */
    padding: 10px;
    position: relative;
    padding: 1rem;
    padding-top: 5px;
    margin-top: 1rem;
    overflow: scroll;
    max-height: 300px;
}

ul.search-ul {
    list-style: none;
}

li.li-element {
    background: white;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.chakra-badge{
    padding: 1rem;
    width: 100%;
    /* margin:1rem; */
}

.card-box{
    width:100%;
    padding: 1rem;
}

.card-field{
    width: 100%;
    padding: 1rem;
}

.card-field-two{
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
}

/* media query less than 768px*/
@media (max-width: 768px) {
    .card-field-two{
        flex-wrap: wrap;
    }
}


.text-center{
    text-align: center;
}

.text-center h3{
    font-size: 1.2rem;
    text-align: center;
    font-weight: 500;
    color: rgb(141, 161, 180);
    margin-top: 1rem;
    text-transform: uppercase;
}

.searchrelative{
    position:relative;
}

.searchlist{
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 10;
    margin-top: 10px;
    height: 200px;
    overflow: scroll;
    position: absolute;
    width: 94%;
}

.searchlist ul {
    list-style-type: none;
}

.searchlist ul li{
    padding: 5px;
    cursor: pointer;
    padding-left: 17px;
}

.searchlist ul li:first-child{
    padding-top: 10px;
}

.searchlist ul li:last-child{
    padding-bottom: 10px;
}

.searchlist ul li:hover{
    background-color: #f5f5f5;
}
